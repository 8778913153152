.card {
  background-color: #f3ecec;
  color: black;
  max-width: 17rem;
  overflow: hidden;
  height: 20rem;
  transition: opacity 0.3s ease; 
  margin-top: 1rem;
  border-radius: 1rem;
  padding: 3rem;
  border: 0.1rem solid rgb(165, 165, 165);
  box-shadow:0rem 0.3rem  6px rgba(0, 0, 0, 0.322) ;
  margin-right: 0rem !important;
  /* Add a transition effect for smooth opacity changes */
}
.card-info{
  padding: 1rem;
  width:90%;
  font-size: 0.85rem;
  color: #f3ecec;
}
// .card-hover{
//   color: #f3ecec;
//   background:##d52b1e;
//   display: flex;
//   right: -100%;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   min-height: 20rem;
//   width: 100%;
//   position: absolute;
//   z-index: 3;
//   transition: 0.5s ease;
// }
// .card:hover .hover{
//   top: 0;
// }
.isDisable {
  opacity: 0.5;
  pointer-events: none;
}
.card-hover{
  // display: none;
  position: inherit;
  height: 20rem;
  z-index: 3;
  width: 14rem;
  margin-top: -22rem;
  padding: 0.2rem;
  margin-bottom: 2rem;
  background: #d52b1e;
  transition: 0.5s ease-in-out;
}
.card:hover .card-hover{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  // width: 200%;
}
.card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -1rem;
  z-index: 1;

}
.card-icon{
  transform: scale(75%);
}
.card-text{
  text-align: center;
}
.card-link{
  text-decoration: none !important;

}