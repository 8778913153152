.clearbtn{
    width: 17%;
    .lds-button{
        width: 100%;
    }
}
.veevasearchfont{
    .searchfont{
        font-size: 14px;
    }
}
.rm-select-dropdown-campaign{
    .searchfont{
        font-size: 14px;
    }
    .lds-select-wrapper{
        .lds-select-input-wrapper{
            .lds-select-options{
                .lds-button-clear-style{
                    width: 150% !important;
                    min-height: 4rem !important;
                    margin-top: 0.3rem !important;
            }
            }
        }
    }
}
.rm-select-dropdown-indication{
    .lds-select-wrapper{
        .lds-select-input-wrapper{
            .lds-select-options{
                .lds-button-clear-style{
                    width: 150% !important;
                    min-height: 3rem !important;
                    margin-top: 0.3rem !important;
            }
            }
        }
    }
}
.apiStatus{
    width: 360%;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
}