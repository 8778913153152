.hero-bu{
    height: 6rem !important;
}

.bu-table{
    min-height:20rem;
    overflow: hidden !important;
}
.common-table{
    min-height: 15rem !important;
    margin-top: -80px;
}

.overview-container-bu {
    margin-top: 1rem;
    margin-bottom: -1rem;
}

.overview-title-bu{
    align-items: start;
    margin-top: 2rem;
}
.overview-text-bu{
    font-size:2rem;
}
.button-container-bu{
    gap:1rem;
    margin-left: 2rem;
}
.no-data{
    position: absolute;
    margin-top: 5vw;
    margin-left: 40vw;
}
.loader-bu{
    z-index:3;
    position:absolute;
    margin-left:47vw !important;
    margin-top:3rem;
    height:20rem;
}
.table-headers-bu{
    tr{
        th{
            font-weight: 500 !important;
        }
    }
}
.restoreButton-bu{
    display: flex;
    justify-content: space-around
}
.center-bu{
    text-align: center;
}
.edit-icon-bu{
    width: 1.3rem;
    height: 1.3rem;
}
.table-body-bu{
    z-index: 1;
    tr{
        td{
            vertical-align:middle;
        }
    }
}
.buttons-bu{
    font-size: 0.9rem;
    height: 2.5rem;
    width: max-content !important;
margin-top: 0.5rem !important;
}
.table-ends-bu{
    vertical-align: middle !important;

}
.approve-sec-bu{
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    margin: 2rem 0 2rem 0;;
}
.approve-bu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4{
        font-weight: 500;
    }
}
.approve-icon-bu{
    width: 2.5rem !important;
    height: 2.5rem !important;
}
.approve-buttons-bu{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.space-bu{
    margin-top: 2rem;

}