.sidenav aside{
  
  width: 15rem;
};

.sidenav{
  height: auto;
  position: fixed;
  top: 0px;
  transition: background-color .15s;
  width: 100%;
  z-index: 30;
}

.lds-side-nav{
  .lds-side-nav-container{
    .lds-side-nav-top-container{
      margin-top: 60px;
    }
  }

}

#contentId{
  display: none;
}
