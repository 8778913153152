.skeleton{
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading{
    0%{
        background-color: rgba(230, 230, 230, 0.185);
    }
    100%{
        background-color: rgb(226, 226, 226);
    }
}

.loading-page{
    overflow-y: hidden !important;
    overflow-x: hidden;
}
.loading-nav{
    background-color: rgb(255, 255, 255);
    width: 100vw;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 7rem;
}
.loading-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 35%;
}
.loading-logo{
    
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem; 
}
.loading-name{
    
    width: 10rem;
    height: 2rem;
    border-radius: 0.5rem;  
}
.loading-role{
    
    width: 14rem;
    height: 3rem; 
    border-radius: 0.5rem; 
}
.loading-logout{
    
    width: 8rem;
    height: 3rem; 
    border-radius: 0.5rem; 
}
.loading-header{
    background-color: rgb(243, 243, 243);
    width: 100vw;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.loading-header-text{
    // rgb(243, 243, 243)
    width: 30rem;
    height: 3rem; 
    border-radius: 0.5rem;  
}

.loading-buttons{

    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 5rem;
    gap: 3rem;
}
.loading-page-name{
    
    width: 14rem;
    height: 4rem; 
    border-radius: 0.5rem;  
}

.loading-button{
   
    width: 10rem;
    height: 3rem; 
    border-radius: 0.5rem;  
}
.loading-table{
    background-color: rgb(243, 243, 243);
    height: 28rem;
}

.loading-table-col{
    background-color: white;
    height: 5rem;
    width: 20vw;
    border-radius: 0rem 0rem 1rem 1rem;
}
.laoding-table-header{
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 0.5rem;
    height: 6rem;
}