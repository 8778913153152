.layout-sticky{
    z-index:1;
    width:100vw;
}
.logo-image{
    width: 5rem;
    padding: 0.5rem 0;
    margin-left: -2rem;
}
.layout-container{
    display:flex;
    align-items:center;
}
.load{
    margin-left: 45vw;
    margin-top: 5rem;
}
.settings{
    margin-right: -2rem !important;
    width: 2rem !important;
    height: 2rem !important;
    transition: 500ms ease;
    fill: #d52b1e;
}
.settings:hover{
    transform: rotate(45deg);
}
.user-name{
    margin-top: 0.5rem;
    margin-right:1.5rem;
    font-weight: 500;
    font-size: 1.2rem; 
}
.layout-select{
    width: 17rem;
    border-radius: 0.3rem;
}
.side-nav{
    z-index:3;
}