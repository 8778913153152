.thead-alignment{
    // height: 50px;
    align-content: center;
}
.headingMargin{
    color: #212121 !important;
    text-decoration: none !important;
}

.header-height{
    height: 74px;
    div{
        margin-left: 10px;
    }
}

.buttonCareUpFill{
    .lds-link {
        color: black;
    };
    margin-bottom: 15px;
    // margin: -9px 0px;
}

.buttonCareDownFill{
    .lds-link {
        color: black;
    };
    margin-top: -7px;
}

.up-caret{
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid #383434;
}

.down-caret{
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid #383434;
}

.opacity-button{
    opacity: 1;
}
.no-opacity-button{
    opacity: 0.3;
}