.button-clear-style{
    height: 2% !important;
    word-wrap: break-word;
}
.subLoader{
    margin: 0px 48px;
}
.col-2{
    .lds-select-wrapper{
        .lds-select-input-wrapper{
            .lds-select-options{
                .lds-button-clear-style{
                    width: 150% !important;
                    min-height: 3.0rem !important;
                    margin-top: 0.3rem !important;
            }
            }
        }
    }
}
.file-name-home{
    word-wrap: break-word;
}
.file-upload-button{
    margin-left: 33%;
}

.error{
    font-weight: 500;
    font-size: 16px; 
    font-family: "Roboto"; 
    color: #ef4a5f;
}

.ml-font{
    font-size: 15px;
    font-weight: 400;
}

.css-1jqq78o-placeholder {
    color: hsl(0deg 1.53% 5.69%) !important;
}