/* CustomAccordion.scss */
.accordion {
  border-bottom: 3px solid #d52b1e; /* Light red border */
  margin-bottom: 10px;
  overflow: hidden; /* Ensures content fits inside the accordion */
}

.accordion-header {
  background-color: #ffffff; /* Red background */
  padding: 5px;
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // border-bottom: 2px solid #000000; /* Light red border */
  transition: background-color 0.3s ease;
}
.header-text{
  font-weight: 500;
  font-size: 18px;

}

.accordion-body {
  padding: 15px;
  border-top: 2px solid #d52b1e;
  border-left: 2px solid #d52b1e;
  border-right: 2px solid #d52b1e;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #333; /* Dark text color */
  font-size: 18px;
  max-height: 260px;
  overflow: auto;
}

.accordion-body a {
  color: #f44336; /* Red color for links */
  text-decoration: none; /* Remove underline from links */
}

.accordion-body a:hover {
  text-decoration: underline; /* Underline links on hover */
}

.accordion-header span {
  font-size: 18px;
}

.accordion-body {
  transition: max-height 0.3s ease;
}

.accordion-body.expanded {
  max-height: 500px; /* Arbitrary large value to accommodate content */
}

.accordion-body.collapsed {
  max-height: 0;
  overflow: hidden;
}
