.heading{
    text-align: center;
  }
.overvieweditcat{
    margin-top: 1%;
    margin-left: 2%;
    margin-bottom: 1%;
}
.search {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-left: 2rem;
    align-items: center;
    width: fit-content;
  }

.buttonGroup {
    margin-top: 22px;
    margin-left: 10px;
}
.searchbtn {
    margin-right: 5px !important;
}
.spinner {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.catTable{
    td {
        vertical-align: middle !important;
        text-align: left !important;
    }
    .lds-table-container.md-break .sortable thead th:last-child > button, .lds-table-container.md-break .sortable thead th:last-child > div {
       display: none !important;
    }
   
    .lds-table-container.md-break .sortable thead th:last-child {
       cursor: default !important;
       width: 20%
    }
   }

.editCatInput {
    width: 50% !important;
    margin-bottom: 10px;
}

.align {
    align-items: center;
    text-align: center;
    width: 100%;
  }

.btnEditCat {
margin-top: 1rem;
  }

.btnCancel {
    margin-right: 10px !important;
}

.centerI {
    display: flex;
    justify-content: center;
    // vertical-align: middle;
}

.attriDiv3 {
    justify-content: left !important;
    display: flex;
}
