tbody{
    tr{
        .rmsName{
            .rmsNamecol{
                // width: 45rem;
                // word-wrap: break-word;
                margin: 0px;
                padding: 0px;
            }
        }
    }
}

.row{
    .col-4 .downloadCSV{
        margin-top: -8px !important;
    }
}

.rms-select-dropdown-campaign{
    .lds-select-wrapper{
        .lds-select-input-wrapper{
            .lds-select-options{
                .lds-button-clear-style{
                    width: 150% !important;
                    min-height: 4rem !important;
                    margin-top: 0.3rem !important;
            }
            }
        }
    }
}
.rmsApiStatus{
    width: 200%;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.downloadIcon{
    .icon-DownloadSimple {
        height: 2.3rem !important;
        width: 3rem !important;
    }
}