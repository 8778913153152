.ldcIconCheck{
    display: inline-block;
    max-width: 23px; 
    max-height: 24px,;
    margin-right: 4px !important; 
    fill: green !important;
}
.tab2{
    display: grid !important;
    grid-template-columns: repeat(4, 3fr);
    grid-gap: 1rem;
}