.audit-table-card
{
    overflow-x: auto;
    word-wrap: break-word;
}

.tableColumn{
    width: 99px;
    overflow-wrap: break-word;
    text-align: left;
}
.dataColumn{
    width: 7rem;
}
.downloadIcon{
    .icon-DownloadSimple {
        height: 3rem !important;
        width: 4rem !important;
    }
}
.iconAlignment{
    vertical-align: middle;
}
