// Chatbot.scss

.chatbot-init-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    align-items: center;
    justify-content: center;
  }

  .chatbot-init-open, .chatbot-init-close{
    background-color: #d52b1e; // Red theme color
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .document, .video{
    margin-top: 10px !important;
  }
  

  .chatbot-layout {
    position: fixed;
    bottom: 20px;
    right: 100px;
    width: 500px;
    padding: 5px;
    background-color: #d8d8d8;
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.733);
    // box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
    // border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    z-index: 500;
    height: 600px;
    max-height: 600px;
  }
  
  .chatbot-header {
    background-color: #d8d8d8; // Red theme color
    color: rgb(0, 0, 0);
    // padding: 5px;
    padding-left: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 20px;
    text-align: center;
  }
  
  .chatbot-messages {
    max-height: 400px; 
    overflow-y: auto; 
    border: 1px solid #ccc; 
    background: #f9f9f9; 
    flex: 1;
    padding: 15px;
    border-radius: 10px;
    flex-direction: column;
    

  }
  

  .player{
    z-index: 1000;
  }
  
  .chatbot-message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    max-width: 80%;
    overflow: auto;
  }
  
  .chatbot-message.bot {
    background-color: #d6d6d6;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  
  .chatbot-message.user {
    background-color: #d52b1e; // Red theme color
    color: white;
    align-self: flex-end;
  }
  
  .chatbot-buttons {
    padding: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
 
  }
  
  .chatbot-buttons button {
    width: fit-content;
    white-space: nowrap;
    flex: 1;
    margin: 0 5px;
    padding: 10px;
    border: none;
    background-color: #ffffff; // Red theme color
    color: rgb(0, 0, 0);
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chatbot-buttons button:hover {
    background-color: #d52b1e;
  }
  
  .chatbot-footer {
    padding: 10px;
    text-align: center;
  }
  
  .chatbot-close-button {
    padding: 10px;
    border: none;
    background-color: #d52b1e; // Red theme color
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chatbot-close-button:hover {
    background-color: #e64a19;
  }
  