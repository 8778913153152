.hero {
    height: 15rem !important;
    background-color: white !important;
}

.overview-container {
    gap: 1rem !important;
    margin-top: -10px;
    display: flex;
    justify-content: start;
    margin-left: 3rem;

}

.checkboxColor{
    background-color: red !important;
}

.overview-title {
    margin-top: 0.4rem !important;
}

.overview-text {
    font-size: 2.5rem !important;
}

.button-container {
    gap: 1rem !important;
    width: 65vw !important;
}

.loader-csv {
    z-index: 3;
    position: relative;
    margin-top: 0.8rem;
    margin-right: 0.5rem;
}
.overview-text{
    font-size:2.5rem !important;
}
.button-container{
    gap:1rem !important;
    width: 65vw !important;
}
.radio{
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: -1rem;
    margin-top: 1rem;

}

.second-row {
    margin-top: 0.5rem;
    margin-left: auto;
}

.buttons {
    font-size: 1rem !important;
    margin-right: 1rem !important;
    height: 2.5rem;
    width: max-content !important;
    margin-top: 0.5rem !important;
}

.table-ends {
    text-align: end !important;
    vertical-align: middle !important;

}

.options-medcom {
    margin-top: 1rem;
    font-weight: 600 !important;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 0.2rem;
    font-size: 1.2rem;
}
.options-mt{
    background: #f0f0f0;
    padding: 2rem 2rem 2rem 6rem;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-left: 1rem ;
    font-size: 1.2rem;
}
.icon-col{
    display: flex;
    justify-content: space-evenly;
}

.table-icons {
    width: 1.3rem !important;
    height: 1.3rem !important;
}

.icon-class {
    min-width: 20px;
    max-width: 22px;
}

.row-style {
    margin-top: 15px;
    height: 3vh;
}

.rejected {
    background-color: rgb(241, 184, 184)
}

.spinner {
    margin-right: -100rem;
    margin-bottom: 20rem;
    height: 10vh !important;
}

.attriDiv {
    display: flex;
    justify-content: right;
}

.iconStyle {
    display: flex;
    align-items: initial;
    max-height: 1rem;
    margin-top: 0.3rem;
}

.icon-size {
    height: 15px;
    margin-bottom: -2rem;
    width: 2.5rem;
    min-width: 2.5rem;
}

.pointer {
    cursor: pointer;
}

.icon-s {
    margin-top: -0.3rem;
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.checkField {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    background: #d52b1e;
    z-index: 10;
    border: 0.2px solid black;
    border-radius: 0.5rem;
}

.checkField0 {
    display: flex;
    font-size: 15px;
    flex-direction: column;
    margin-left: 40px;

}

.table-bl {
    min-height: 20rem !important;
}

.statusSearch {
    font-size: 0.8rem !important;
    width: 8rem;
}

.textField {
    width: 120px;
    margin-top: 3px;
}

.textFieldCat {
    width: 120px;
    margin: 3px auto;
}

.row-field {
    margin: 10px;
}

.lds-button.cancelBtn {
    margin-right: 10px;
}

.add-cat-modal>.lds-toast.lds-toast-theme-error {
    z-index: 1000;
}