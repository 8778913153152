.index-section {
    margin: 2rem 1rem 2rem 16rem;
}

.clearbt {
    margin: 24px 0px 0px 0% !important;
    width: 100%;
}
.clearButton{
    width: 12% !important;
}

.downloadcsv {
    margin: 24px 0px 0px 0px !important;
}

.secondRow {
    .col-3{
        width: 20% !important;
    }
}

.lds-modal-body{
    .reasonFailed{
        margin: 0px 0px 7px 10rem;
    }
}
.secondRow{
    .datePicker{
        padding: 0px;
        .lds-datepicker-wrapper{
            width: 100%;
            div{
                width: 100%;
                .lds-datepicker-input-wrapper{
                    .lds-datepicker-input{
                        max-width: 90%;
                    }
                    .lds-button{
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}
