.text-input {
    width: 2rem;
}

.space{
    padding-top: 10px;
    display: flex;
    column-gap: 20px;  
    align-items: flex-start;
}

.bu-spinner{
    z-index:3 !important;
    position:absolute !important;
    margin-left:48vw !important;
    margin-top:3rem !important;
    height:10rem !important;
}

.save{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.asterik-required{
    color: red;
    font-size: 1.3rem
    }
.system{
    position: right;
    // display: flex;
    // align-items: center;
}
.lds-select-options{
    z-index: 40 !important; 
}

.select-box{
    width: 20rem !important;
}

