.approve-sec{
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    margin: 2rem 0 2rem 0;;
  }
  .approve{
      display: flex;
      flex-direction: column;
      justify-content: center;
      h4{
          font-weight: 700;
          text-align: center;
      }
  }
  .approve-icon{
      width: 3.5rem !important;
      height: 3.5rem !important;
  }
  .approve-buttons{
      display: flex;
      justify-content: center;
      gap: 2rem;
  }  
  .space{
    padding-top: 10px;
    display: flex;
    column-gap: 20px;  
    align-items: flex-start;
  }
  .hightlighttxt {
    background-color: yellow;
  }