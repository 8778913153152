.header {
    // margin-left : 17rem;
    background-color: #d52b1e;
    color: #fff;
    height: 10vh;
    line-height: 10vh;
    font-size: 30px;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.page-content {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: #fff;

}

.index-section {
    margin: 2rem 1rem 2rem 16rem;
}
