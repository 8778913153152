.downloadIcon {
  margin-right: -2px !important;
  text-decoration: none !important;

  .icon-DownloadSimple {
    height: 2.3rem !important;
    width: 3rem !important;
  }
}

.textName {
  font-size: x-small;
  display: flex;
  margin-left: 4px;
  color: black;
  text-decoration: none;
}

.iconSize {
  font-size: x-large;
}