.accordionlm {
    margin-top: 4%;
}
.page-content {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: #fff;
}
.downloadCSV{
    margin-top: -8px !important;
}
.accordionlm {
    .barChart{
        width: 400px;
        height: 300px;
        padding: 0px;
    }
}
#accordion1-panel{
    padding: 16px 0px 0px 47px;
    height: 16rem;
}
#modalAccordion-panel{
    padding: 16px 0px 0px 47px;
    height: 13rem;
}
.pieChart{
    canvas{
        height: 160px !important;
        width: 160px !important;
    }
}
.barChart{
    height: 11rem !important;
}
.chartRow{
    padding: 0px;
    margin: 0px 0px 0px -3rem !important;
}
.highlight{
    background-color: #ffa5008c;
  }
.noRecord{
    margin: 35px 0px;
}

.campaign-filters-row-second{
    .datePicker{
        padding: 0px;
        .lds-datepicker-wrapper{
            width: 100%;
            div{
                width: 100%;
                .lds-datepicker-input-wrapper{
                    .lds-datepicker-input{
                        max-width: 90%;
                    }
                    .lds-button{
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}