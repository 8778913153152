
// .container{
//     // display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 5rem;
// }
.tacct-tiles {
    padding: 25px 25px 0px 25px;
    display: flex;
    justify-content: center;
    align-items: center;

  }