.header {
    // margin-left : 17rem;
    background-color: #d52b1e;
    color: #fff;
    height: 10vh;
    line-height: 10vh;
    font-size: 30px;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    padding: 0px auto;
    h2{
        margin-left: 10rem;
    }
}

.adhocCardBody{
    margin: 0px 0px 0px 1%;
    padding-right: 10px;
}
.adhocCard {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(15, 15, 15, 0.2);
    margin: 2rem 1rem 2rem 16rem;
}