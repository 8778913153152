.drag-drop{
    width: 100%;
    height: 150px;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.lds-modal-body{
    div{
        p{
            .x-circle{
                color: rgb(22, 21, 21);
                margin: 0px 2px;
            }
            .file-name{
                word-wrap: break-word;
            }
        }
    }
}
.aliging-item{
    margin-left: 43%;
}

.file_validation{
    color: #D52B1E;
    width: 58%;
    float: right;
}
.verifiedFile{
    color: green;    
    width: 58%;
    float: right;
}