.ta-center{
    .targetList {
        font: 400 2.125rem/3rem Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important;
        font-size: 20px !important;
    }
}
.modalbutton{
    margin-left: -30%;
}
// .continueBtn {
//     margin-left: 17%;
// }
.newSubmitData{
    font: 400 2.125rem/3rem Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important;
    font-size: 32px;
}
.modalData2{
    font-size: 19px;
}