.restoreButton{
    text-align: left;
}
.Hero{
    display:flex !important;
    // gap: 4rem;
    width: 100vw !important;
}
.cont{
    margin-top:0.4rem;

}

.space{
    display: flex;
    column-gap: 20px;  
    align-items: flex-start;
}

.archive-header{
    font-size: 3rem !important;
}
.archive-table{
    min-height: 20rem !important;
}
.th-one{
    text-align: center !important;
}
.archive-table-body{
    z-index: 1 !important;
}
.archive-icons{
    width: 1.3rem !important; 
    height: 1.3rem !important;
}
.icon-container{
     display: flex !important;
     justify-content: space-around !important;
}
.archive-spinner{
    z-index:3;
    position:absolute !important;
    margin-left:47vW !important;
    margin-top:3rem !important;
    height:20rem !important;
}
.val-buttons{
    gap:2rem !important;
    display:flex !important; 
    justify-content:center !important;
}
.ov-button {
    margin-left: 4rem;
}

.ov-button2 {
    margin-left: 1rem;
}

.attriDivArchive{
    display: flex;
}



