tr{
    .rmFileName{
        .rmFileNamecol{
            width: 12rem;
            word-wrap: break-word;
        }
    }
}

.rmDatePicker{
    .datePicker{
        // padding-left: 3%;
        .lds-datepicker-wrapper{
            width: 100%;
            div{
                width: 100%;
                .lds-datepicker-input-wrapper{
                    .lds-datepicker-input{
                        max-width: 90%;
                    }
                    .lds-button{
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}