.hero{
    height: 10rem;
    background-color: white !important;

}
.overview-container{
    gap:4rem;
    display: flex;
    justify-content: start;
}
.overview-title{
    align-items: start;
    margin-top: 2rem;
}
.overview-text{
    font-size:2rem;
}
.button-container{
    gap:1rem;
    width: inherit;
}
.loader{
    z-index:3;
    position:absolute;
    margin-left:47vw !important;
    margin-top:3rem;
    height:20rem;
}
.table-headers{
    tr{
        th{
            font-weight: 500 !important;
        }
    }
}
.restoreButton{
    display: flex;
    justify-content: space-around
}
.center{
    text-align: center;
}
.edit-icon{
    width: 1.3rem;
    height: 1.3rem;
}
.table-body{
    z-index: 1;
    tr{
        td{
            vertical-align:middle;
        }
    }
}
.buttons{
    font-size: 0.9rem;
    height: 2.5rem;
    width: max-content !important;
margin-top: 0.5rem !important;
}
.table-ends{
    text-align: left !important;
    vertical-align: middle !important;
}
.approve-sec{
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    margin: 2rem 0 2rem 0;;
}
.approve{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4{
        font-weight: 500;
    }
}
.approve-icon{
    width: 2.5rem !important;
    height: 2.5rem !important;
}
.approve-buttons{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.space{
    margin-top: 2rem;
}